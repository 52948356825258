import _ from 'lodash'
import {Model} from './model'
import {Project} from './project'
import {Plan} from './plan'
import {User} from './user'
import {Money} from './money'
import {Account} from './account'

export class Payment extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }

		_.assignIn(this, {
			description		: data.description,
			user 			: data.user != null && _.isObject(data.user) ? new User(data.user) : data.user,
			account 		: data.account != null && _.isObject(data.account) ? new Account(data.account) : new Account({'@id': data.account}),
			subscription 	: data.subscription != null && _.isObject(data.subscription) ? new Model(data.subscription) : new Model({'@id': data.subscription}),
			status 			: data.status,
			statusO			: _.find(STATUS, s => s.id == data.status),
			data 			: data.signature,
			project 		: data.project != null && _.isObject(data.project) ? new Project(data.project) : data.project,
            plan 			: data.plan != null && _.isObject(data.plan) ? new Plan(data.plan) : data.plan,
            price 			: new Money(data.price),
			profit			: new Money(data.profit),
			errorMessages	: data.errorMessages,
			errorGroup		: data.errorGroup,
			info 			: data.info ? {
				net 		: new Money(data.info.net),
				plan 		: new Money(data.info.plan),
				toMerchant 	: new Money(data.info.toMerchant),
				toPartner 	: data.info.toPartner ? new Money(data.info.toPartner) : null,
				toService 	: new Money(data.info.toService),
				bankFee 	: new Money(data.info.bankFee),
				total 		: new Money(data.info.total)
			} : null
		});

		this.profitAmount = this.info.toMerchant.price();
	}
	
	isRequested () { return this.status == STATUS[0].id }
	isChecked () { return this.status == STATUS[1].id }
	isPaid () { return this.status == STATUS[2].id }
	isRefund () { return this.status == STATUS[3].id;}
	isError () { return this.status == STATUS[4].id; }

	setProject (v) 	{ this.project = v ? v instanceof Project ? v : (_.isObject(v) ? new Project(v) : v) : v}
}

export const STATUS = [
	{id: 'REQUESTED', titleRU: 'REQUESTED', titleEN: 'REQUESTED'},
	{id: 'CHECKED', titleRU: 'CHECKED', titleEN: 'CHECKED'},
	{id: 'PAID', titleRU: 'Успешно', titleEN: 'USED'},
	{id: 'REFUND', titleRU: 'Возврат', titleEN: 'REFUND'},
	{id: 'ERROR', titleRU: 'Ошибка', titleEN: 'ERROR'}];

export const GATEWAY = [
	'UNITPAY',
	'TINKOFF',
	'STRIPE_UK',
	'MAMO_UAE'
]