import api from '@/api/modules/referrers'
import * as store from '../store'
import {Metric} from '@/models/metric'

const state = {
	list 	: [],
	total	: null
}

const getters = _.defaults({
	total	: state => state.total,
    list    : state => state.list.length > 0 ? state.list : [],
}, store.getters);

const actions = {
	async items ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.items(data);
			commit('list', res.data['hydra:member']);
			commit('total', res.data['hydra:totalItems'])
			commit(store.PENDING, false);
			return state.list;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async id ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.id(data.id);
			commit('opened', _.cloneDeep(res.data));
			commit(store.PENDING, false);
			return new Referrer(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},
}

const mutations = _.defaults({
	list ( state, i ) {
		state.list = _.map( i, p => p instanceof Metric ? p : new Metric(p));
	},

	total ( state, items ) {
		state.total = items;
	},
}, store.mutations);

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
