import api from '@/api/modules/plans';
import { Plan } from '@/models/plan';
import { Resource } from '@/models/resource';
import * as store from '../store';
import { _ } from 'core-js';

const state = _.defaults({
	edited: null,
	list: [],
	waiting: null,
	plansNeuro: []
}, store.state);

const getters = _.defaults({
	edited: state => state.edited,
	list: state => state.list,
	isWaiting: state => state.waiting,
	plansNeuro: state => state.plansNeuro
}, store.getters);

const actions = {
	async plansPlain ({state, commit}, data) {
		commit(store.START_REQUEST);;

		try {
			let res = await api.plansPlain(data);
			commit('list', res.data['hydra:member']);
			commit(store.PENDING, false);
			return state.list;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async add ({ commit, getters }, data ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.addPlans(data);
			res.data.canBeDeleted = true;

			commit('add', res.data);
			commit(store.PENDING, false);
			return _.last(state.list);
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async edit ({ commit, getters }, data ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.edit(data);
			commit('edit', _.cloneDeep(res.data));
			commit(store.PENDING, false);
			return new Plan(res.data);
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async activate ({ commit, getters }, data = {} ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.activate(data);
			commit(store.PENDING, false);
			return true;
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async archive ({ commit, getters }, data = {} ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.archive(data);
			commit(store.PENDING, false);
			return true;
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async remove ({ commit, getters }, data ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.remove(data);
			commit('remove', data);
			commit(store.PENDING, false);
			return true;
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async putChannels ({ commit, getters }, data = {} ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.planChannels(data);
			commit(store.PENDING, false);
			return true;
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async putGroups ({ commit, getters }, data = {} ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.planGroups(data);
			commit(store.PENDING, false);
			return true;
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async plansNeuro ({ commit, getters }, data ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.plansNeuro();

			commit('setPlansNeuro', _.cloneDeep(res.data['hydra:member']));
			commit(store.PENDING, false);
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},
}

const mutations = _.defaults({
	edited ( state, i ) {
		state.edited = i;
	},

	add ( state, i ) {
		state.list.push( new Plan(i) );
	},

	list ( state, i ) {
		state.list = _.map( i, p => p instanceof Plan ? p : new Plan(p));
	},

	activate ( state, id ) {
		let index = _.findIndex(state.list, (i) => i.id == id);

		if ( index == - 1  || state.list.length <= index ) return;

		state.list[index].setArchive(false);
	},

	archive ( state, item ) {
		let index = _.findIndex(state.list, (i) => i.id == item.id);

		if ( index == - 1  || state.list.length <= index ) return;

		state.list[index].setArchive();
	},

	remove ( state, item ) {
		let index = _.findIndex(state.list, (i) => i.id == item.id);

		if ( index == - 1  || state.list.length <= index ) return;

		state.list.splice(index, 1);
	},

	edit ( state, item ) {
		let index = _.findIndex(state.list, v => v.id == item.id || v['@id'] == item['@id']);

		if ( index != -1 ) {
			item.canBeDeleted = state.list[index].canBeDeleted;
			state.list[index] = item instanceof Plan ? item : new Plan(item);
		}

		state.list = _.cloneDeep(state.list);
	},

	setWaiting ( state, v ) {
		state.waiting = v;
	},

	setChannels ( state, items ) {
		_.each(state.list, l => {
			_.each(items, i => {
				let index = _.findIndex(l.channels, r => r.id == i.id || r.id == i['@id'] )
				if ( index != -1 ) l.channels[index] = i instanceof Resource ? i : new Resource(i);
			})
		})
	},

	setGroups ( state, items ) {
		_.each(state.list, l => {
			_.each(items, i => {
				let index = _.findIndex(l.groups, r => r.id == i.id || r.id == i['@id'] )
				if ( index != -1 ) l.groups[index] = i instanceof Resource ? i : new Resource(i);
			})
		})
	},

	putChannels ( state, {id, items} ) {
		let index = _.findIndex(state.list, p => p.id == id );
		if ( index == - 1  || state.list.length <= index ) return;
		state.list[index].channels = items;
	},

	putGroups ( state, {id, items} ) {
		let index = _.findIndex(state.list, p => p.id == id );
		if ( index == - 1  || state.list.length <= index ) return;
		state.list[index].groups = items;
	},

	editChannel ( state, item ) {
		_.each(state.list, (l, i) => {
			let index = _.findIndex(l.channels, g => g.id == item.id );
			if ( index != -1 ) l.updateChannel(item);
		})

		state.list = _.cloneDeep(state.list);
	},

	editGroup ( state, item ) {
		_.each(state.list, (l, i) => {
			let index = _.findIndex(l.groups, g => g.id == item.id );
			if ( index != -1 ) l.updateGroup(item);
		})

		state.list = _.cloneDeep(state.list);
	},

	removeResource ( state, i ) {
		_.each(state.list, p => {
			_.remove(p.channels, v => v.id == i.id);
			_.remove(p.groups, v => v.id == i.id);
		})

		state.list = _.cloneDeep(state.list);
	},

	setPlansNeuro(state, i) {
		state.plansNeuro = _.map(i, p => p instanceof Plan ? p : new Plan(p));
	},

}, store.mutations);

export default {
	namespaced: true,
	actions,
	state,
	getters,
	mutations,
}
