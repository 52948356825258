import _ from 'lodash';
import { Model } from './model';
import { User } from './user';
import { Plan } from './plan';
import { Money } from './money';

export class RecoveryPaymentsEvents extends Model {
	constructor(data) {
		super(data);
		if (_.isNil(data)) { return }

		_.assignIn(this, {
			user: data.user instanceof User ? data.user : new User(data.user),
			date: data.date,
			id: data.id,
			mrr: data.mrr instanceof Money ? data.mrr : new Money(data.mrr),
			plan: data.plan instanceof Plan ? data.plan : new Plan(data.plan),
			status: data.status,
			errorGroup: data.errorGroup
		});
	}
}
