import axios from 'axios';
import  {API, APIURL } from '../index';

export default _.defaults({
	URL: APIURL + 'bots',
	add(data) {
		return axios.post(`${this.URL}`, data);
	},
	edit(data) {
		let id = data.id;
		delete data.id;

		return axios.put(`${this.URL}/${id}`, data);
	},
	refresh(data) {
		let id = data.id;
		delete data.id;

		return axios.post(`${this.URL}/${id}/refresh`, data);
	},
}, API);
