import _ from 'lodash';
import { Model } from './model';
import { Money } from './money';


export class RecoveryEfficiency extends Model {
	constructor(data) {
		super(data);
		if (_.isNil(data)) { return }

		let month = new Date(data.year).getMonth();
		_.assignIn(this, {
			atRisk: data.atRisk,
			outFlow: data.outFlow,
			recovered: data.recovered,
			onRecovery: data.onRecovery,
			mrr: data.mrr ? _.each(data.mrr, (m, i) => { data.mrr[i] = new Money(m)}) : [],
			year: month,
			_rowVariant: month === new Date().getMonth() ? 'warning' : '',
		});
	}
}
