import axios from 'axios'
import {API, APIURL} from '../index'

export default _.defaults({
    URL: APIURL + 'channels',
    channels ( data = {} ) {
		  return axios.get(`${this.URL}`, {params: {...data}});
    },
    groups ( data = {} ) {
      return axios.get(`${APIURL}groups`, {params: {...data}});
    },
    channelId ( id ) {
		  return axios.get(`${APIURL}channels/${id}`);
    },
    groupId ( id ) {
      return axios.get(`${APIURL}groups/${id}`);
    },
    channel_check ( id ) {
		  return axios.post(`${APIURL}channels/${id}/check_rights`, {params: {}});
    },
    group_check ( id ) {
      return axios.post(`${APIURL}groups/${id}/check_rights`, {params: {}});
    },
    removeChannel ( data ) {
      return axios.delete(`${APIURL}channels/${data.id}`);
    },
    removeGroup ( data ) {
      return axios.delete(`${APIURL}groups/${data.id}`);
    }
}, API);
