import { isDemo } from '@/constants/config';
import { STORAGE } from '@/api/storage';
import api from '@/api/modules/auth';
import store from '@/store';

let params = (new URL(document.location)).searchParams;
let authRequestId = params.get("authRequestId");

export default (to, from, next) => {
	if (isDemo)
		return next();

	if (STORAGE.hasToken() && !authRequestId) {
		next();
	} else if (authRequestId) {
		let data = {};
		data.id = authRequestId;

		api.qrLogin(data.id).then(v => {
			if (v.data && v.data.token && v.data.user.replace(/[^a-zA-Z]/g, "") == 'merchants') {
				STORAGE.set({token: v});
				store.commit('auth/token', v.data.token);
				api.me().then(v => {
					store.commit('auth/logged', v.data);
					STORAGE.setUser(v.data);
					next();
				});
			} else {
				next();
			}
		});
	} else {
		STORAGE.clear();
		next('/login');
	}
}
