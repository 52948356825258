import { defaultDirection } from '../constants/config'
import moment from 'moment'
import filters from '@/filters'
import {DEVICE} from '@/utils/device'
import {bus} from '@/main.js'

export const addCommas = nStr => {
	nStr += ''
	var x = nStr.split('.')
	var x1 = x[0]
	var x2 = x.length > 1 ? '.' + x[1] : ''
	var rgx = /(\d+)(\d{3})/
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + ',' + '$2')
	}
	return x1 + x2
}

export const mapOrder = (array, order, key) => {
	array.sort(function (a, b) {
		var A = a[key]
		var B = b[key]
		if (order.indexOf(A + '') > order.indexOf(B + '')) {
			return 1
		} else {
			return -1
		}
	})
	return array
}

export const getDateWithFormat = () => {
	const today = new Date()
	let dd = today.getDate()
	let mm = today.getMonth() + 1 // January is 0!

	var yyyy = today.getFullYear()
	if (dd < 10) {
		dd = '0' + dd
	}
	if (mm < 10) {
		mm = '0' + mm
	}
	return dd + '.' + mm + '.' + yyyy
}

export const getCurrentTime = () => {
	const now = new Date()
	return now.getHours() + ':' + now.getMinutes()
}

export const ThemeColors = () => {
	let rootStyle = getComputedStyle(document.body)
	return {
		themeColor1: rootStyle.getPropertyValue('--theme-color-1').trim(),
		themeColor2: rootStyle.getPropertyValue('--theme-color-2').trim(),
		themeColor3: rootStyle.getPropertyValue('--theme-color-3').trim(),
		themeColor4: rootStyle.getPropertyValue('--theme-color-4').trim(),
		themeColor5: rootStyle.getPropertyValue('--theme-color-5').trim(),
		themeColor6: rootStyle.getPropertyValue('--theme-color-6').trim(),
		themeColor1_10: rootStyle.getPropertyValue('--theme-color-1-10').trim(),
		themeColor1_30: rootStyle.getPropertyValue('--theme-color-1-30').trim(),
		themeColor1_50: rootStyle.getPropertyValue('--theme-color-1-50').trim(),
		themeColor2_10: rootStyle.getPropertyValue('--theme-color-2-10').trim(),
		themeColor3_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
		themeColor4_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
		themeColor5_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
		themeColor6_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
		primaryColor: rootStyle.getPropertyValue('--primary-color').trim(),
		primaryColor_50: rootStyle.getPropertyValue('--primary-color-50').trim(),
		successColor: rootStyle.getPropertyValue('--success-color').trim(),
		foregroundColor: rootStyle.getPropertyValue('--foreground-color').trim(),
		separatorColor: rootStyle.getPropertyValue('--separator-color').trim()
	}
}
export function RandomColor () {
	let c = Math.floor(Math.random()*16777215).toString(16);

	if ( c.length < 6 ) return RandomColor();

	return c;
}

export function addAlpha(color, opacity) {
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}

export const BrightColors = () => {
	let rootStyle = getComputedStyle(document.body)
	return [
		{title : 'green', value : '59CB94'},
		{title : 'blue', value : 'A7B3FF'},
		{title : 'red', value : 'FF7C7C'},
		{title : 'yellow', value : 'FCB64D'},
		{title : 'gray', value : 'CBD2D8'},
    	{title : 'purple', value : 'B971F3'},
		{title : 'pink', value : rootStyle.getPropertyValue('--pink-color').trim().replace('#', '')},

		{title : 'indigo', value : rootStyle.getPropertyValue('--indigo').trim()},
		{title : 'teal', value : rootStyle.getPropertyValue('--teal').trim()},
		{title : 'cyan', value : rootStyle.getPropertyValue('--cyan').trim()},
		{title : 'orange', value : rootStyle.getPropertyValue('--orange').trim()},
		{title : 'info', value : rootStyle.getPropertyValue('--info').trim()}
	]
}

export const chartTooltip = {
	backgroundColor: ThemeColors().foregroundColor,
	titleFontColor: ThemeColors().primaryColor,
	borderColor: ThemeColors().separatorColor,
	borderWidth: 0.5,
	bodyFontColor: ThemeColors().primaryColor,
	bodySpacing: 10,
	xPadding: 15,
	yPadding: 15,
	cornerRadius: 0.15,
	mode: 'nearest',
  	position: 'nearest',
	callbacks: {
		label: function(context, a, b) {
			if (a.datasets[context.datasetIndex] && a.datasets[context.datasetIndex].label )
		    	return a.datasets[context.datasetIndex].label + ' ' + context.yLabel;

			return context.yLabel;
		}
	}
}

export const tooltipCustomLine = {
	enabled: false,
	mode: 'index',
	intersect: false,
  	position: 'nearest',
	custom: function(tooltip, e) {
		var tooltipEl = document.getElementById('chartjs-tooltip');

		if ( DEVICE.isMobile() ) return tooltipCustomLineMobile(tooltip, this._chart.config.data.datasets, e);

		if (!tooltipEl) {
			tooltipEl = document.createElement('div');
			tooltipEl.id = 'chartjs-tooltip';
			tooltipEl.className = 'chartjs-tooltip-custom';
			tooltipEl.innerHTML = "" // <div class='list-legend list-unstyled m-0'>
			document.body.appendChild(tooltipEl);
		}

		// Hide if no tooltip
		if (tooltip.opacity === 0) {
			tooltipEl.style.opacity = 0;
			tooltipEl.style.top = -99 + 'px';
			return;
		}

		// Set Text
		if (tooltip.body) {
			tooltipEl.innerHTML = tooltipCustomTitle(tooltip, this._chart.config.data.datasets) + tooltipCustomLegend(tooltip, this._chart.config.data.datasets);
		}

		tooltipCustomPosition(tooltip, tooltipEl, this._chart);
	}
};

function tooltipCustomLegend ( tooltip, datasets ) {
	let out 	= '<ul class="list-unstyled mb-0">';

	tooltip.body.map(b => b.lines).forEach((body, i) => {
		var title 	= body[0].split(': ')[0];
		var value 	= body[0].split(': ')[1];

		var span = '<span class="list-legend--item--key list-legend--item--key-sm" style="background:' + datasets[tooltip.dataPoints[i].datasetIndex].borderColor + '"></span>';
		out += '<li class="d-flex justify-content-between list-legend--item"><p class="mb-0">' + span + '<span class="mr-4">' + title + '</span></p><span class="text-right pl-4">' + value + '</span></li>';
	});

	out += '</ul>';

	return out;
}

function tooltipCustomTitle (tooltip, datasets) {
	let out 		= '';
	let date 		= null;
	let dateTitle 	= null;
	let dayTitle 	= null;

	if ( datasets[tooltip.dataPoints[0].datasetIndex].labelsRaw && datasets[tooltip.dataPoints[0].datasetIndex].labelsRaw[tooltip.dataPoints[0].index] ) {
		date = datasets[tooltip.dataPoints[0].datasetIndex].labelsRaw[tooltip.dataPoints[0].index];
		if ( date.split('-').length == 3 ) {
			if ( date.includes('T') ) {
				dateTitle = moment(date).format('DD.MM.YYYY HH:00, dddd');
			} else {
				dateTitle 	= moment(date).format('DD.MM.YY');
				dayTitle 	= filters.capitalize(moment(date).format('dddd'));
			}
		}

		if ( date.split('-').length == 2 ) {
			dateTitle = filters.capitalize(moment(date).format('MMM YYYY'));
		}
	}

	var titleLines = dateTitle != null ? [dateTitle] : (filters.capitalize(tooltip.title) || []);

	if ( dayTitle != null ) {
		out = '<p class="mb-1 text-muted">'+ dayTitle +'</p>';
	}
	titleLines.forEach(function(title) {
		out += '<p class="mb-1">' + title + '</p>';
	});

	return out;
}

function tooltipCustomPosition ( tooltip, el, chart ) {
	let position = chart.canvas.getBoundingClientRect();
	let left = position.left + tooltip.caretX + 10;

	if ( left + el.clientWidth > window.innerWidth ) {
		left -= el.clientWidth + 20;
	}

	el.style.opacity = 1;
	el.style.left = left + 'px';
	el.style.top = position.top + tooltip.caretY - el.clientHeight - 15 + 'px';
}

function tooltipCustomLineMobile (tooltip, datasets, e) {
	setTimeout( () => {
		if ( tooltip.opacity == 0 ) return;
		bus.$bvModal.show('modalcharttooltip');
		setTimeout( () => {
			document.getElementById('chartTooltipTitle').innerHTML = tooltipCustomTitle(tooltip, datasets)
			document.getElementById('chartTooltipLegend').innerHTML = tooltipCustomLegend(tooltip, datasets)
		}, 10)
	}, 200)
}

export const tooltipCustomPie = {
	enabled: false,
	mode: 'nearest',
  	position: 'nearest',
	custom: function(tooltip) {
		var tooltipEl = document.getElementById('chartjs-tooltip-pie');

		if (!tooltipEl) {
			tooltipEl = document.createElement('div');
			tooltipEl.id = 'chartjs-tooltip-pie';
			tooltipEl.className = 'chartjs-tooltip-custom';
			tooltipEl.innerHTML = "<ul class='list-legend list-unstyled m-0'>"
			document.body.appendChild(tooltipEl);
		}

		// Hide if no tooltip
		if (tooltip.opacity === 0 || !tooltip.body || (tooltip.body[0].lines && tooltip.body[0].lines[0].includes('99999'))) {
			tooltipEl.style.opacity = 0;
			tooltipEl.style.top = -99 + 'px';
			return;
		}

		// Set caret Position
		tooltipEl.classList.remove('above', 'below', 'no-transform');
		if (tooltip.yAlign) {
			tooltipEl.classList.add(tooltip.yAlign);
		} else {
			tooltipEl.classList.add('no-transform');
		}

		function getBody(bodyItem) {
			return bodyItem.lines;
		}

		// Set Text
		if (tooltip.body) {
			var titleLines = tooltip.title || [];
			var bodyLines = tooltip.body.map(getBody);

			var innerHtml = '';

			titleLines.forEach(function(title) {
				innerHtml += '<li>' + title + '</li>';
			});
			innerHtml += '';


			bodyLines.forEach(function(body, i) {
				var title 	= body[0].split(': ')[0];
				var value 	= body[0].split(': ')[1];

				var colors = tooltip.labelColors[i];
				var style = 'background:' + colors.backgroundColor;
				var key = '<span class="list-legend--item--key" style="' + style + '"></span>';
				innerHtml += '<li class="list-legend--item">' + '<p class="mb-0">' + key + title + ' &nbsp;&nbsp;' + value + '</p></li>';
			});

			var tableRoot = tooltipEl.querySelector('ul');
			tableRoot.innerHTML = innerHtml;
		}

		var position = this._chart.canvas.getBoundingClientRect();

		let left 		= window.event.pageX - tooltipEl.clientWidth/2;
		let top 		= window.event.pageY - tooltipEl.clientHeight - 20;
		let topMobile 	= position.top + tooltip.caretY - tooltipEl.clientHeight - 15;
		let leftMobile 	= position.left + tooltip.caretX - tooltipEl.clientWidth/2;

		tooltipEl.style.opacity = 1;
		tooltipEl.style.left = (DEVICE.isMobile() ? leftMobile : left) + 'px';
		tooltipEl.style.top = (DEVICE.isMobile() ? topMobile : top) + 'px';
	}
};

export const chartTooltipPie = {
	backgroundColor: ThemeColors().foregroundColor,
	bodyFontColor: ThemeColors().primaryColor,
	bodyFontSize: 16,
	borderColor: ThemeColors().separatorColor,
	borderWidth: 0,
	footerFontColor: 'rgba(8, 40, 70, 0.5)',
	footerFontSize: 12,
	displayColors: true,
	bodySpacing: 10,
	boxPadding: 80,
	xPadding: 8,
	yPadding: 10,
	cornerRadius: 8,
	boxWidth: 8,
	boxHeight: 8,
	mode: 'nearest',
  	position: 'nearest',
	callbacks: {
		labelPointStyle: function(context) {
			return {
				pointStyle: 'circle',
				rotation: 0
			};
		},
		label: function(context, a, b) {
			if (a.datasets[0].data[context.index] )
		    	return a.datasets[0].data[context.index];

			return context.yLabel;
		},
		footer: function(context, a, b) {
			if (a.datasets[0].data[context[0].index] )
		    	return '     ' + a.labels[context[0].index];

			return context[0].yLabel;
		}
	}
}

export const centerTextPlugin = {
	afterDatasetsUpdate: function (chart) {},
	beforeDraw: function (chart) {
		var ctx = chart.chart.ctx
		ctx.restore()

		var activeLabel = chart.data.labels[0]
		var activeValue = _.reduce(chart.data.datasets[0].data, (sum, n) => {
			return sum += Number(n);
		}, 0);

		var dataset = chart.data.datasets[0]
		var meta = dataset._meta[Object.keys(dataset._meta)[0]]
		var total = meta.total

		var activePercentage = parseFloat(((activeValue / total) * 100).toFixed(1))
		activePercentage = chart.legend.legendItems[0].hidden
			? 0
			: activePercentage

		if (chart.pointAvailable) {
			activeLabel = chart.data.labels[chart.pointIndex]
			activeValue = chart.data.datasets[chart.pointDataIndex].data[chart.pointIndex]

			dataset = chart.data.datasets[chart.pointDataIndex]
			meta = dataset._meta[Object.keys(dataset._meta)[0]]
			total = meta.total
			activePercentage = parseFloat(((activeValue / total) * 100).toFixed(1))
			activePercentage = chart.legend.legendItems[chart.pointIndex].hidden
				? 0
				: activePercentage
		}

		// ctx.font = '36px Nunito, sans-serif'
		ctx.fillStyle = ThemeColors().primaryColor
		// ctx.textBaseline = 'middle'

		// var text = activePercentage + '%'
		// var textX = Math.round((width - ctx.measureText(text).width) / 2)
		// var textY = height / 2
		// ctx.fillText(text, textX, textY)

		if ( chart.tooltip._lastActive.length == 0 ) activeValue = total;
		if ( activeValue == 99999 ) activeValue = 0;

		this.fillCenterText(chart, activeValue);

	},

	fillCenterText ( chart, activeValue ) {
		activeValue = !activeValue || activeValue == null ? activeValue : _.reduce(chart.data.datasets[0].data, (sum, n) => sum += Number(n), 0);
		var ctx = chart.chart.ctx;
		var width = chart.chartArea.right
		var height = chart.chartArea.bottom

		ctx.restore();
		// ctx.save();

		ctx.font = '20px Golos, Nunito, sans-serif'
		ctx.textBaseline = 'middle'

		var text2 = activeValue
		var textX2 = Math.round((width - ctx.measureText(text2).width) / 2)
		var textY2 = height / 2
		ctx.fillText(text2, textX2, textY2)

		ctx.save()
	},
	beforeEvent: function (chart, event, options) {
		var firstPoint = chart.getElementAtEvent(event)[0]

		if (firstPoint) {
			chart.pointIndex = firstPoint._index
			chart.pointDataIndex = firstPoint._datasetIndex
			chart.pointAvailable = true
		}

		// if ( event && event.type == 'mouseout' ) {
		// 	setTimeout( () => {
		// 		this.fillCenterText(chart);
		// 	}, 400)
		// }
	}
}
export const getDirection = () => {
	let direction = defaultDirection
	if (localStorage.getItem('direction')) {
		const localValue = localStorage.getItem('direction')
		if (localValue === 'rtl' || localValue === 'ltr') {
			direction = localValue
		}
	}
	return {
		direction,
		isRtl: direction === 'rtl'
	}
}

export const setDirection = localValue => {
	let direction = 'ltr'
	if (localValue === 'rtl' || localValue === 'ltr') {
		direction = localValue
	}
	localStorage.setItem('direction', direction)
}
