<template lang='pug'>
	b-nav.pt-0.breadcrumb-container.d-block.d-lg-inline-block
		b-breadcrumb(:items='items' :class='className')
</template>

<script>
export default {
	props: ['heading', 'list', 'className'],
	data () {
		return {
			items: []
		}
	},
	methods: {
		getUrl (path, sub, index) {
			var pathToGo = '/' + path.split(sub)[0] + sub
			if (pathToGo === '/app') {
				pathToGo = '/'
			}
			return pathToGo
		}
	},
	mounted () {
		if ( this.list ) {
			this.items = this.list;
			return;
		}
		
		let path = this.$route.path.substr(1)
		let rawPaths = path.split('/')

		for (var pName in this.$route.params) {
			if (rawPaths.includes(this.$route.params[pName])) {
				rawPaths = rawPaths.filter(x => x !== this.$route.params[pName])
			}
		}
		rawPaths.map((sub, index) => {
			this.items.push({
				text: this.$t('menu.' + sub),
				to: this.getUrl(path, sub, index)
			})
		})
	},
	watch: {
		list (nv) {
			this.items = this.list;
		}
	}
}
</script>
