export const DEVICE = {
	isSafari ( )    { return window.navigator.userAgent.toLowerCase().includes('safari') && !window.navigator.userAgent.toLowerCase().includes('chrome'); },
	isSafari17 ( )    { return this.isSafari() && this.version() == 17 && !this.isiPhone() },
	isiPhone ()		{ return this.isSafari() && window.navigator.userAgent.toLowerCase().includes('iphone') },
	version () 		{
		let v = window.navigator.userAgent.toLowerCase().match(/Version\/(\d*)/iu);

		if ( v && v[1] ) return v[1];

		return 0;
	},
	isFirefox ( )   { return window.navigator.userAgent.toLowerCase().includes('firefox'); },
	isMobile ( )    { return window.innerWidth < 768; },
	isLarge ( )     { return window.innerWidth < 992; },
	isVeryLarge ( ) { return window.innerWidth < 1200; },
	isTablet ( )    { return window.innerWidth < 1024 && window.innerWidth >= 768; },
	isPad ()        { return window.innerWidth >= 768 && window.innerWidth < 1024 },
	isPadPro ()     { return window.innerWidth >= 1024 && window.innerWidth < 1200 },
	isLgScreen ()   { return window.innerWidth >= 1200 && window.innerWidth <= 1400 },
	isIE ()         { return window.navigator.userAgent.toLowerCase().includes('windows')},
	getCSSVar ( name ) { return getComputedStyle(document.documentElement).getPropertyValue(name).replace('#', '').trim(); },
	hasScroll () {
		if(document.body.scrollHeight == window.innerHeight) return false;

		return document.body.scrollHeight + 140 > window.innerHeight;
	},
	copy ( text ) {
		var textArea,
			range,
			selection;

		//createTextArea

		textArea = document.createElement('textArea');
		textArea.value = text;
		document.body.appendChild(textArea);

		//selectText
		if (this.isOS()) {
			range = document.createRange();
			range.selectNodeContents(textArea);
			selection = window.getSelection();
			selection.removeAllRanges();
			selection.addRange(range);
			textArea.setSelectionRange(0, 999999);
		} else {
			textArea.select();
		}

		//copyToClipboard
		document.execCommand('copy');
		document.body.removeChild(textArea);
	},

	isWindows() {
		if (window.navigator.userAgent.toLowerCase().indexOf("windows") != -1) return true;

		return false;
	}
}