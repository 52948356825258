import _ from 'lodash';
import { Model } from './model';
import { Money } from './money';
import { Bot } from './bot';
import { Plan, PERIODSFREE } from './plan';
import { Consumer } from './consumer';
import { User } from './user';
import { ErrorPayment } from './error';
import { Comebacker } from './comebacker';

export class Subscription extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }

		_.assignIn(this, {
			nextAt 			: data.nextAt,
			cancelledAt 	: data.cancelledAt,
			willKickAt		: data.willKickAt,
			kickAt			: data.kickAt,
			kickedAt		: data.kickedAt,
			user 			: data.user != null ? new Consumer(data.user) : null,
			status 			: data.status,
			plan 			: data.plan != null ? (_.isString(data.plan) ? new Plan({'@id': data.plan}) : new Plan(data.plan)) : null,
			counters 		: data.counters,
			email 			: data.email || data.lastEmail,
			lastNonRefundPayment : data.lastNonRefundPayment,
			lastPayment		: data.lastPayment,
			promotion		: data.promotion,
			promoCode		: data.promoCode,
			extraPrice		: data.extraPrice ? new Money(data.extraPrice) : data.extraPrice,
			price			: data.price ? new Money(data.price) : data.price,
			profit			: data.profit ? new Money(data.profit) : data.profit,
			trialInterval	: data.trialInterval,
			isTrial			: data.isTrial,
			free 			: data.trialInterval == null ? _.first(PERIODSFREE) : _.find(PERIODSFREE, p => p.id == data.trialInterval),
			comebacker		: data.comebacker ? new Comebacker(data.comebacker) : null,
			comebackerAttempt: data.comebackerAttempt,
			botView			: data.botView ? new Bot(data.botView) : null,
			bot			 	: data.botView && data.botView.bot ? new Bot(data.botView.bot) : null,
			botViewUserId	: data.botView && data.botView.user ? data.botView.user.id : null,
			tokens			: data.tokens
		});

		if ( data.lastErrorPayment && data.lastErrorPayment.errorGroup ) { this.errorGroup = data.lastErrorPayment.errorGroup}

		this.setCounters();
	}

	setCounters () {
		if ( this.counters && this.counters.other )
			_.each(this.counters.other, (c, i) => {
				if ( c && c.amount && c.currency ) {
					this.counters.other[i] = new Money(c);
				}
			})
		if ( this.counters && this.counters.world2ru )
			_.each(this.counters.world2ru, (c, i) => {
				if ( c && c.amount && c.currency ) {
					this.counters.world2ru[i] = new Money(c);
				}
			})
	}

	isCompleted() {
		return this.status == SUBSCRIPTION_STATUS[1] && this.kickedAt != null;
	}

	isCompletedSubscription() {
		return this.status == SUBSCRIPTION_STATUS[1];
	}

	isCancelledButNotKicked() {
		return this.status == SUBSCRIPTION_STATUS[1] && this.kickedAt == null;
	}

	isUndefined() {
		return this.status == SUBSCRIPTION_STATUS[0] && !this.user;
	}

	isStatusTrial() {
		return this.status == SUBSCRIPTION_STATUS[2];
	}

	isStatusPayRequested() {
		return this.lastPayment && this.lastPayment.status == 'REQUESTED';
	}

	isStatusPayPaid() {
		return this.lastPayment && this.lastPayment.status == 'PAID';
	}

	isOnSupport() {
		return this.hasPaymentHard() && !this.isCompletedSubscription() && !this.hasPaymentPaid() || this.hasPaymentHard() && !this.isCompletedSubscription() && this.isStatusPayRequested() ||
				this.hasPaymentDeclineUnknown() && !this.isCompletedSubscription() && !this.hasPaymentPaid() || this.hasPaymentDeclineUnknown() && !this.isCompletedSubscription() && this.isStatusPayRequested() ||
				this.hasPaymentNoFunds() && !this.isCompletedSubscription() && !this.hasPaymentPaid() || this.hasPaymentNoFunds() && !this.isCompletedSubscription() && this.isStatusPayRequested() ||
				this.hasPaymentCritical() && !this.isCompletedSubscription() && !this.hasPaymentPaid() ||
				this.hasPaymentSoft() && !this.isCompletedSubscription() && !this.hasPaymentPaid() || this.hasPaymentSoft() && !this.isCompletedSubscription() && this.isStatusPayRequested() ||
				this.hasPaymentUnknown() && !this.isCompletedSubscription() && !this.hasPaymentPaid() ||
				this.hasPaymentLimit() && !this.isCompletedSubscription() && !this.hasPaymentPaid() || this.hasPaymentLimit() && !this.isCompletedSubscription() && this.isStatusPayRequested();
	}

	isOnSupportCriticalAndUnknown() {
		return this.hasPaymentCritical() && !this.kickAt || this.hasPaymentUnknown() && !this.kickAt;
	}

	isOneTime() {
		return this.plan && this.plan.period && this.hasPaymentCritical() && this.hasPaymentUnknown();
	}

	isOneTimeAudience() {
		return this.plan && this.plan.period;
	}

	hasPaymentCritical () { return this.errorGroup && ErrorPayment.isCritical(this.errorGroup) }
	hasPaymentHard () { return this.errorGroup && ErrorPayment.isHard(this.errorGroup) }
	hasPaymentSoft () { return this.errorGroup && ErrorPayment.isSoft(this.errorGroup) }
	hasPaymentDeclineUnknown () { return this.errorGroup && ErrorPayment.isDeclineUnknown(this.errorGroup) }
	hasPaymentFraud () { return this.errorGroup && ErrorPayment.isFraud(this.errorGroup) }
	hasPaymentNoFunds () { return this.errorGroup && ErrorPayment.isNoFunds(this.errorGroup) }
	hasPaymentUnknown () { return this.errorGroup && ErrorPayment.isUnknown(this.errorGroup) }
	hasPaymentLimit () { return this.errorGroup && ErrorPayment.isLimit(this.errorGroup) }

	hasPaymentPaid () { return this.lastNonRefundPayment && this.lastNonRefundPayment['@id'] && this.lastNonRefundPayment.errorGroup == null; }

	getProfitMerchatOtherValue () {
		return this.counters.other.toMerchant.price();
	}
	getProfitMerchatOther () {
		return this.counters.other.toMerchant.priceWithCurrency();
	}
	getProfitMerchatWorld2ruValue () {
		return this.counters.world2ru.toMerchant.price();
	}
	getProfitMerchatWorld2ru () {
		return this.counters.world2ru.toMerchant.priceWithCurrency();
	}
	getTotalPayment () {
		return this.counters.other.total.amount == "0" ? this.counters.world2ru.total.priceWithCurrency() : this.counters.other.total.priceWithCurrency()
	}
	getTotalPaymentOther () {
		return this.counters.other.total.priceWithCurrency()
	}
	getTotalPaymentWorld2ru () {
		return this.counters.world2ru.total.priceWithCurrency()
	}
	isPaymentPaidAfterUseComebacker () {
		return (this.comebacker
			&& this.comebackerAttempt
			&& this.lastPayment
			&& this.comebackerAttempt.createdAt <= this.lastPayment.createdAt
			&& this.lastPayment.status === 'PAID')
	}
}

export const SUBSCRIPTION_STATUS = ['ACTIVE', 'CANCELLED', 'TRIAL', 'COMPLETED'];
