import { _ } from 'core-js';
import {Model} from './model'
import {Money} from './money'
import { BrightColors, RandomColor } from '@/utils'

const brightColors = BrightColors();
export const UTMKeySeparator = '->';

export class MetricUtm extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }
		let keys = data.key.split(UTMKeySeparator);
		let totalOther 		= {amount: 0, currency: 'RUB'};
		let totalWorld2ru 	= {amount: 0, currency: 'USD'};

		_.assignIn(this, data);
		this.newIncomeOther          = this.newIncomeOther ? new Money(this.newIncomeOther) : null;
		this.newIncomeWorld2ru       = this.newIncomeWorld2ru ? new Money(this.newIncomeWorld2ru) : null;
		this.income          		= this.income ? new Money(this.income) : null;
		this.recurrentIncomeOther    = this.recurrentIncomeOther ? new Money(this.recurrentIncomeOther) : null;
		this.recurrentIncomeWorld2ru = this.recurrentIncomeWorld2ru ? new Money(this.recurrentIncomeWorld2ru) : null;


		if ( data.newIncomeOther && data.newIncomeOther.amount ) {
			totalOther.amount = Number(data.newIncomeOther.amount);
		}
		if ( data.newIncomeWorld2ru && data.newIncomeWorld2ru.amount ) {
			totalWorld2ru.amount = Number(data.newIncomeWorld2ru.amount);
		}

		if ( data.recurrentIncomeOther && data.recurrentIncomeOther.amount ) {
			totalOther.amount += Number(data.recurrentIncomeOther.amount);
		}
		if ( data.recurrentIncomeWorld2ru && data.recurrentIncomeWorld2ru.amount ) {
			totalWorld2ru.amount += Number(data.recurrentIncomeWorld2ru.amount);
		}

		this.totalIncomeOther = Number(totalOther.amount / 100);
		this.totalIncomeWorld2ru = Number(totalWorld2ru.amount / 100);

		this.isExtended         = false;
		this.deep               = keys.length-1;
		this._checked           = this.deep == 0;
		this.parent             = keys.length > 1 ? keys.slice(0, keys.length-1).join(UTMKeySeparator) : null;
		this.title              = _.last(keys);
		this.children           = [];
		this.color 				= this.color ? this.color : RandomColor();
	}

	set checked ( i ) {
		if ( i ) {
			_.each(this.subCategory, c => {
				c.checked = false;
			});
		}

		this._checked = i;
	}
	get checked () {
		if ( !this.subCategory || this.subCategory.length == 0 ) return this._checked;
		if ( this.subCategory && _.some( this.subCategory, c => c.checked ) ) return false;

		return this._checked;
	}

	isSubCategory ()    { return this.parent != null }

	getTitle ( list ) { return this.title == 'TOTAL' ? list['TOTAL'] : this.title; }
	getKey ( list ) { return this.title == 'TOTAL' ? list['TOTAL'] : this.key; }
	getChildrenTree () {
		let o = [...this.children];

		_.each( this.children, c => {
			o.push(...c.getChildrenTree())
		})

		return o;
	}
	getChildrenKeys () {
		if ( this.children.length > 0 ) return _.uniq(_.map(this.children, c => [c.key, ...c.getChildrenKeys()] ));

		return [this.key];
	}

	findChild ( key ) {
		if ( this.key == key ) return this;

		if ( this.children.length > 0 ) {
			let item = null;
			_.each( this.children, c => {
				if ( c.findChild() ) item = c;
			})

			return item;
		}

		return null;
	}

	setExtended( val = null ) {
		this.isExtended = val != null ? val : !this.isExtended;

		if ( !this.isExtended )
			_.each(this.children, c => c.setExtended(false));

	}

	getUtmObject () {
		let out = {};

		_.forOwn(this, (v,k) => {
			if ( k.includes('utm') )
				out[k] = v;
		})

		return _.compact(out);
	}

	sortChildren ( opt ) {
		this.children = _.sortBy(this.children, opt).reverse();
		_.each(this.children, c => c.sortChildren(opt) );
	}
}

export function prepareMetricUtmList ( items ) {
	if ( items == null ) {
		return {};
	}

	let keys = _.keys(items.items);
	let out	= [];
	let total 	= {
		key: "TOTAL",
		newIncomeOther: {amount: 0, currency: "RUB"},
		newIncomeWorld2ru: {amount: 0, currency: "USD"},
		recurrentIncomeOther: {amount: 0, currency: "RUB"},
		recurrentIncomeWorld2ru: {amount: 0, currency: "USD"},
		totalIncomeOther 			: {amount: 0, currency: 'RUB'},
		totalIncomeWorld2ru 		: {amount: 0, currency: 'USD'},
		income: {amount: 0, currency: "RUB"},
		recurrentCount: 0,
		subsCount: 0,
		viewsCount: 0,
		conversion: 0,
		merchantCount: 0,
		projectsCount: 0,
		colorBg		: brightColors[0].value,
		color 		: brightColors[0].value,
	};

	let sortOption = 'viewsCount';

	_.each(items.items, (i, ind) => {
		i.key = ind;
		i.hasChild = _.some(keys, v => v.indexOf(i.key + UTMKeySeparator) == 0);

		out.push(i instanceof MetricUtm ? i : new MetricUtm(i));
		if ( i.key.includes(UTMKeySeparator) ) return;

		total.subsCount 			+= Number(i.subsCount);
		total.recurrentCount 		+= Number(i.recurrentCount);
		total.viewsCount 			+= Number(i.viewsCount);
		total.merchantCount 		+= Number(i.merchantCount);
		total.conversion 			+= Number(i.conversion);
		total.projectsCount 		+= Number(i.projectsCount);

		if ( i.newIncomeOther && i.newIncomeOther.amount ) {
			total.newIncomeOther.amount 		+= Number(i.newIncomeOther.amount) * ( i.newIncomeOther instanceof Money ? 100 : 1);
			total.totalIncomeOther.amount = Number(i.newIncomeOther.amount) * ( i.newIncomeOther instanceof Money ? 100 : 1);
		}
		if ( i.newIncomeWorld2ru && i.newIncomeWorld2ru.amount ) {
			total.newIncomeWorld2ru.amount 		+= Number(i.newIncomeWorld2ru.amount) * ( i.newIncomeWorld2ru instanceof Money ? 100 : 1);
			total.totalIncomeWorld2ru.amount = Number(i.newIncomeWorld2ru.amount) * ( i.newIncomeWorld2ru instanceof Money ? 100 : 1);
		}
		if ( i.income && i.income.amount )
			total.income.amount 		+= Number(i.income.amount) * ( i.income instanceof Money ? 100 : 1);
		if ( i.recurrentIncomeOther && i.recurrentIncomeOther.amount ) {
			total.recurrentIncomeOther.amount+= Number(i.recurrentIncomeOther.amount) * ( i.recurrentIncomeOther instanceof Money ? 100 : 1);
			total.totalIncomeOther.amount = Number(i.recurrentIncomeOther.amount) * ( i.recurrentIncomeOther instanceof Money ? 100 : 1);
		}
		if ( i.recurrentIncomeWorld2ru && i.recurrentIncomeWorld2ru.amount ) {
			total.recurrentIncomeWorld2ru.amount+= Number(i.recurrentIncomeWorld2ru.amount) * ( i.recurrentIncomeWorld2ru instanceof Money ? 100 : 1);
			total.totalIncomeWorld2ru.amount = Number(i.recurrentIncomeWorld2ru.amount) * ( i.recurrentIncomeWorld2ru instanceof Money ? 100 : 1);
		}

		total.totalIncomeOther = new Money(total.totalIncomeOther);
		total.totalIncomeWorld2ru = new Money(total.totalIncomeWorld2ru);

		if ( !_.isUndefined(i.merchantCount) ) sortOption = 'merchantCount';
	})

	total.conversion = total.conversion == 0 ? 0 : total.subsCount / total.viewsCount * 100;
	total.conversion = Math.floor(total.conversion * 100) / 100;

	_.each(out, (i,ind) => {
		if ( i.parent ) _.find(out, o => o.key == i.parent).children.push( i );
	})

	_.each(out, (i,ind) => {
		i.sortChildren(sortOption);
	})


	items.items = _.sortBy(_.filter(out, o => !o.key.includes(UTMKeySeparator)), sortOption).reverse();
	items.items.unshift(new MetricUtm(total));
	_.each(items.items, (i, ind) => {
		if ( ind < 5) i.color = brightColors[ind].value
	});

	return items;
}

export const utmList = ['utmSource','utmMedium','utmCampaign','utmContent','utmTerm'];
