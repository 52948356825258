import axios from 'axios'
import {API, APIURL} from '../index'

export default _.defaults({
	URL: APIURL + 'accounts',

	addWithdrawals (data) {
		let id = data.id;
		delete data.id;

		return axios.post(`${this.URL}/${id}/withdrawal`, data);
	},

	cards ( data ) {
		return axios.get(`${APIURL}cards`, {params: {...data}});
	},

	addCard ( data ) {
		return axios.post(`${APIURL}cards`, data);
	},

	addCardDev ( data ) {
		let id = data.id;
		delete data.id;

		return axios.post(`${this.URL}/${id}/dev_card`, data);
	},

	addCardExists ( data ) {
		let id = data.id;
		delete data.id;

		return axios.put(`${APIURL}cards/${id}`, data);
	},

	removeCard ( data ) {
		let id = data.id;
		delete data.id;

		return axios.delete(`${this.URL}/${id}/card`, {data: data});
	},

	removeCardFromAll ( data ) {
		let id = data.id;
		delete data.id;

		//return axios.delete(`${APIURL}cards/${id}`);
		return axios.put(`${APIURL}accounts/${id}`, {card: null});
	},

	partnerFinance (data) {
		return axios.get(`${APIURL}partners/finance`, {params: {...data}});
	},

	addShop ( data ) {
		let id = data.id;
		delete data.id;

		return axios.post(`${APIURL}shops`, data);
	},

	editShop ( data ) {
		let id = data.id;
		delete data.id;

		return axios.put(`${APIURL}shops/${id}`, data);
	},

	removeShop ( data ) {
		let id = data.id;
		delete data.id;

		return axios.delete(`${APIURL}shops/${id}`);
	},

	exportWithdrawals ( data ) {
		let id = data.id;
		delete data.id;

		return axios.get(`${APIURL}withdrawals/${id}/report`, {
			responseType: 'blob'
		});
	},

	reports ( data ) {
		return axios.get(`${APIURL}export_files`, {params: {...data}});
	},

	downloadReports ( data ) {
		let id = data.id;
		delete data.id;

		return axios.get(`${APIURL}export_files/${id}/download`, {
			responseType: 'blob'
		});
	},

	paymentsCSV ( data ) {
		return axios.get(`${APIURL}payments/default_export`, {params: {...data}});
	},

	withdrawalsCSV ( data ) {
		return axios.get(`${APIURL}withdrawals/default_export`, {params: {...data}});
	},

	findCompany ( data ) {
		return axios.get(`${APIURL}find/company`, {params: {...data}});
	},

	findBank ( data ) {
		return axios.get(`${APIURL}find/bank`, {params: {...data}});
	},

	findAddress ( data ) {
		return axios.get(`${APIURL}suggest/address`, {params: {...data}});
	},

	rates ( data ) {
		return axios.get(`${APIURL}public/rates`, {params: {...data}});
	},

	checkUsdt ( data ) {
		return axios.get(`https://api.trongrid.io/v1/accounts/${data}`, {params: {}});
	},

	editAccounts ( data ) {
		let id = data.id;
		delete data.id;

		return axios.put(`${this.URL}/${id}.jsonld`, data);
	},
}, API);
