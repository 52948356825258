import _ from 'lodash';
import { Model } from './model';
import {RecoveryEfficiency} from "@/models/recoveryEfficiency";

export class RecoveryEfficiencyByYears extends Model {
	constructor(data) {
		super(data);
		if (_.isNil(data)) { return }

		_.assignIn(this, {
			title: data.title,
			years: data.years ? _.map(data.years, p => new RecoveryEfficiency(p)) : data.years
		});
	}
}
