import { STORAGE } from '@/api/storage';

export const ROUTES_COMMON = {
	'404': {
		path: "/404",
		component: () => import("@/views/app/main"),
		children: [
			{
				path	: "/",
				name	: '404',
				component: () =>import("@/views/404")
			},
		]
	},
	LOGIN: {
		path: "/login",
		name: 'login',
		props: true,
		component: () => import("@/views/user/Login")
	},
	LOGOUT: {
		path: "/logout",
		name: "logout",
		component: () => import("@/views/user/Logout")
	},
	RU_TERMS: {
		path: "/ru_terms",
		component: () => import("@/views/EmptyWithHeaderAndFooter"),
		children: [
			{
				path: "/",
				name: 'ru_terms',
				component: () => import("@/views/RUTerms")
			},
		]
	},
	TERMS: {
		path: "/terms",
		component: () => import("@/views/EmptyWithHeaderAndFooter"),
		children: [
			{
				path: "/",
				name: 'terms',
				component: () => import("@/views/Terms")
			},
		]
	},
	RU_SUB_TERMS: {
		path: "/ru_sub_terms",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'ru_sub_terms',
				component: () => import("@/views/SubTerms")
			},
		]
	},
	SUB_TERMS: {
		path: "/sub_terms",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'sub_terms',
				component: () => import("@/views/SubTerms")
			},
		]
	},
	PRIVACY_ACCEPT: {
		path: "/privacy_accept",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'privacy_accept',
				component: () => import("@/views/PrivacyAccept")
			},
		]
	},
	RU_PRIVACY_ACCEPT: {
		path: "/ru_privacy_accept",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'ru_privacy_accept',
				component: () => import("@/views/PrivacyAccept")
			},
		]
	},
	AD_ACCEPT: {
		path: "/ad_accept",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'ad_accept',
				component: () => import("@/views/AdAccept")
			},
		]
	},
	RU_AD_ACCEPT: {
		path: "/ru_ad_accept",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'ru_ad_accept',
				component: () => import("@/views/AdAccept")
			},
		]
	},
	RU_AGENCY_CONTRACT: {
		path: "/ru_agency_contract",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'ru_agency_contract',
				component: () => import("@/views/AgencyContract")
			},
		]
	},
	AGENCY_CONTRACT: {
		path: "/agency_contract",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'agency_contract',
				component: () => import("@/views/AgencyContract")
			},
		]
	},
	PARTNERS_AGREEMENT: {
		path: "/partners_agreement",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'partners_agreement',
				component: () => import("@/views/PartnersAgreement")
			},
		]
	},
	RU_PRIVACY: {
		path: "/ru_privacy",
		component: () => import("@/views/EmptyWithHeaderAndFooter"),
		children: [
			{
				path: "/",
				name: 'ru_privacy',
				component: () => import("@/views/RUPrivacy")
			},
		]
	},
	RU_PAYMENT_PROCESSING: {
		path: "/ru_payment_processing",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'ru_payment_processing',
				component: () => import("@/views/PaymentProcessing")
			},
		]
	},
	PRIVACY: {
		path: "/privacy",
		component: () => import("@/views/EmptyWithHeaderAndFooter"),
		children: [
			{
				path: "/",
				name: 'privacy',
				component: () => import("@/views/Privacy")
			},
		]
	},
	PAYMENT_PROCESSING: {
		path: "/payment_processing",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'payment_processing',
				component: () => import("@/views/PaymentProcessing")
			},
		]
	},
	COOKIE: {
		path: "/cookie",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'cookie',
				component: () => import("@/views/Cookie")
			},
		]
	},
	RU_TECH: {
		path: "/ru_tech",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'ru_tech',
				component: () => import("@/views/RUTech")
			},
		]
	},
	TECH: {
		path: "/tech",
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'tech',
				component: () => import("@/views/Tech")
			},
		]
	},
	ERROR: {
		path: "/error",
		component: () => import("@/views/app/main"),
		children: [
			{
				path	: "/",
				name	: 'error',
				component: () =>import("@/views/404")
			},
		]
	},
	OTHER: {
		path: "*",
		redirect: '/404',
		component: () => import("@/views/app/main"),
		children: [
			{
				path	: "/",
				name	: 'other',
				component: () =>import("@/views/404")
			},
		]
	}
};

export function fnRouterBeforeEach (to, from, next) {
	/*
	* REFACTOR
	* CHECK USER ROLE AND CHECK PANEL TYPE BY HREF
	*/
	let user = STORAGE.getUser();
	let roles = _.filter(to.matched.map(record => record.meta.roles), i => !_.isUndefined(i));

	if (user && roles && roles.length > 0) {
		if ( !_.some(user.roles, r => roles[0].includes(r)) ) return next({ path: '/login' });

		return next();
	}

	return next();
}
