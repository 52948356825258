import axios from 'axios'
import {API, APIURL} from '../index'
import {DEVICE} from '@/utils/device'

export default _.defaults({
	URL: APIURL + 'projects',

	statistics ( data ) {
		let id = data.id;
		delete data.id;

		return axios.get(`${this.URL}/${id}/statistics`, {params: data});
	},

	activity ( data ) {
		let id = data.id;
		delete data.id;

		return axios.get(`${this.URL}/${id}/activity`, {params: data});
	},

	lastActivity ( data ) {
		let id = data.id;
		delete data.id;

		return axios.get(`${this.URL}/${id}/last_activity`, {params: data});
	},

	view ( data ) {
		let id = data.id;
		delete data.id;

		return axios.post(`${APIURL}public/projects/${id}/view`, data);
	},

	metrics ( data ) {
		let id = data.id;
		delete data.id;

		return axios.get(`${this.URL}/${id}/metrics`, {params: data});
	},

	metricsUtm ( data ) {
		let id = data.id;
		delete data.id;

		return axios.get(`${this.URL}/${id}/utm_metrics`, {params: data});
	},

	attachAccount ( data ) {
		let id = data.id;
		delete data.id;

		return axios.post(`${this.URL}/${id}/set_accounts`, data);
	},

	hash ( data ) {
		if ( DEVICE.isIE() ) return axios.get(`${this.URL}/hashids/${data.id}`);

		return new Promise( (res, rej) => {
			fetch(`${this.URL}/hashids/${data.id}`)
				.then(response => response.json())
				.then(data => res({data: data}))
				.catch( e => rej(e) )
		})
	},

	pixelTest ( data ) {
		let id = data.id;
		delete data.id;

		return axios.post(`${this.URL}/${id}/send_pixel`, data);
	},

	fbPixelTest ( data ) {
		return axios.get(`//connect.facebook.net/signals/config/${data.id}`, {params:{}});
	},

	yaMetricTest ( data ) {
		return axios.get(` https://mc.yandex.ru/watch/${data.id}`, {params:{}});
	},

	promotions ( data ) {
		let id = data.id;
		delete data.id;

		return axios.get(`${APIURL}promotions`, {params: data});
	},

	editPromotion ( data ) {
		let id = data.id;
		delete data.id;

		return axios.put(`${APIURL}promotions/${id}`, data);
	},

	addPromotion ( data ) {
		return axios.post(`${APIURL}promotions`, {...data});
	},

	removePromotion ( data ) {
		return axios.delete(`${APIURL}promotions/${data.id}`);
	},

	archivePromotion ( data ) {
		return axios.post(`${APIURL}promotions/${data.id}/archive`);
	},

	isEditablePromotions ( data ) {
		return axios.get(`${APIURL}promotions/is_editable`, {params: data});
	},

	promocodes ( data ) {
		let id = data.id;
		delete data.id;

		return axios.get(`${APIURL}promo_codes`, {params: data});
	},

	isEditablePromocodes ( data ) {
		return axios.get(`${APIURL}promo_codes/is_editable`, {params: data});
	},

	editPromocode ( data ) {
		let id = data.id;
		delete data.id;

		return axios.put(`${APIURL}promo_codes/${id}`, data);
	},

	addPromocode ( data ) {
		return axios.post(`${APIURL}promo_codes`, {...data});
	},

	removePromocode ( data ) {
		return axios.delete(`${APIURL}promo_codes/${data.id}`);
	},

	archivePromocode ( data ) {
		return axios.post(`${APIURL}promo_codes/${data.id}/archive`);
	},

	checkPromocode ( data ) {
		return axios.get(`${this.URL}/hashids/${data.hashid}/${data.code}`, {params: data});
	},

	covers ( data = {}) {
		return axios.get(`${APIURL}images`, {params: data});
	},

	removeImage ( data ) {
		return axios.delete(`${APIURL}images/${data.id}`);
	},

	requestModeration ( data ) {
		let id = data.id;
		const options={
			headers: {
				'Content-Type': 'application/json'
			},
		}
		return axios.post(`${this.URL}/${id}/request_moderation`,options);
	},
	qrPaymentPage ( data ) {
		let id = data.id;
		delete data.id;

		return axios.get(`${this.URL}/${id}/qr/payment_page`, {
			responseType: 'blob'
		});;
	},
}, API);
