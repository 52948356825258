import { Model } from './model';
import { Money } from './money';

export class Offer extends Model {
    constructor ( data ) {
        super (data);

        _.assignIn(this, data);

        this.cashflowOrganization   = new Money(data.cashflowOrganization);
        this.cashflowWorld2ru   = new Money(data.cashflowWorld2ru);
        this.incomeOrganization = new Money(data.incomeOrganization);
        this.incomeWorld2ru = new Money(data.incomeWorld2ru);
    }
}
