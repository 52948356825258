import {Model, ModelExtend} from './model'
import {Money} from '@/models/money'
import {Card} from '@/models/card'
import {SERVICE_STRING} from '@/utils/string'
import moment from 'moment';

export const PAY_SYSTEM = [
	'UNITPAY',
	'ORGANIZATION',
	'INDIVIDUAL',
	'WORLD2RU'
];

export const PAY_SYSTEM_TYPES = ['RUB', 'OTHER', 'UNIQ'];
export const MIN_SUM_WITHDRAWAL_RU = 1500;
export const MIN_SUM_WITHDRAWAL_USD = 200;

export class Account extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }

		_.assignIn(this, {
			title 		        : data.title,
			currency 	        : data.currency,
			projects            : data.projects,
			system 		        : data.pay_system,
			card 		        : data.card && data.card != null ? new Card(data.card) : data.card,
			canRemove           : data.canRemove,
			currentWithdrawal   : null,
			requisite           : data.requisite instanceof String ? _.last(data.requisite.split('/')) : data.requisite != null ? new Requisite(data.requisite) : data.requisite, //{type: PAY_SYSTEM[3]},
			type                : data.type,
			errorMessage        : data.errorMessage,
			requestedWithdrawals: data.requestedWithdrawals && data.requestedWithdrawals.length > 0 ? _.map(data.requestedWithdrawals, r => {
				r.price = new Money(r.price);
				return r;
			}) : data.requestedWithdrawals,
			shop                : data.shop ? new ModelExtend(data.shop) : null,
			status              : data.status,
			usdtAddress         : data.usdtAddress
		});

		if ( data.income ) {
			this.income = new Money(data.income);
		}
		if ( data.cashflow ) {
			this.cashflow = new Money(data.cashflow);
		}
	}

	isIP () { return this.type == PAY_SYSTEM[1] && this.shop && this.shop.type == PAY_SYSTEM[2]; }
	isOOO () { return this.type == PAY_SYSTEM[1]; }
	isOrganization () { return this.type == PAY_SYSTEM[1]}
	isStripe () { return this.type == PAY_SYSTEM[3]; }
	isTinkoff () { return this.type == PAY_SYSTEM[2]; }
	isUnitpay () { return this.type == PAY_SYSTEM[0]; }
	hasError () { return this.errorMessage && this.errorMessage != null; }
	isRUB () { return this.available && Money.isCurrencyRUB(this.available.currency)}

	hasCard() {
		return this.card && this.card != null && this.card.pan && this.card.isActive();
	}

	canWithdrawal () {
		return this.available != null && Number(this.available.amount) >= this.minSubForWithdrawal() && !this.hasWithdrawal();
	}

	minSubForWithdrawal () {
		if ( this.available == null ) return 100000;

		return this.available.isCurrencyRUB() ? MIN_SUM_WITHDRAWAL_RU : MIN_SUM_WITHDRAWAL_USD;
	}

	hasWithdrawal   () { return this.requestedWithdrawals && (_.isArray(this.requestedWithdrawals) && this.requestedWithdrawals.length > 0) || (!_.isArray(this.requestedWithdrawals) && this.requestedWithdrawals != null); }

	setCurrentWithdrawal ( w ) {
		if ( w == null ) return this.requestedWithdrawals = [];

		this.requestedWithdrawals = [w];
	}

	setRequisite ( data ) {
		this.setShop(data);
	}

	setShop ( data ) {
		this.shop = data != null ? data : data
	}

	hasRequisite () {
		return this.shop && this.shop != null;
	}

	setFinance ( data ) {

		this.available  = data.available ? new Money(data.available[0]) : null;
		this.withdrawal = data.withdrawal ? new Money(data.withdrawal[0]) : null;
		this.hold       = data.hold ? new Money(data.hold[0]) : null;
		this.refund     = data.refund ? new Money(data.refund[0]) : null;
		this.total      = data.total ? new Money(data.total[0]) : {amount: '0', currency: 'RUB'};
		this.next_payout= data.next_payout && data.next_payout.sum ? new Money(data.next_payout.sum) : null;
		this.payouts    = data.payouts ? _.map(data.payouts, p => {
			if ( p.amount && p.amount.amount )
				p.amount = new Money(p.amount);

			if ( p.date )
				p.days = moment(p.date).diff(new Date(), 'hours');

			return p;
		}) : null;
	}

	setFinancePartner ( data ) {
		if ( data.available ) this.available = new Money(data.available);
		if ( data.withdrawal ) this.withdrawal = new Money(data.withdrawal);

		this.total = new Money(data.total ? data.total : {amount: '0', currency: 'RUB'});
	}

	madeWithdrawal ( amount, withdrawal ) {
		this.available.amount   = ((Number(this.available.amount)*100 - amount)/100).toFixed(2);
		this.canRemove          = false;
		this.requestedWithdrawals = [withdrawal];
		this.available          = _.cloneDeep(this.available);
	}

	removeCard () {
		setTimeout( () => this.card = null, 10 );
	}

	addCard (v) {
		if ( !v.status ) v.status = 'ACTIVE';

		this.card = v instanceof Card ? v : new Card(v);
	}

	canRemoveAccount () {
		if ( (_.isArray(this.projects) && this.projects.length > 0) || (this.total && this.total.amount > 0) ) return false;

		return true;
	}

	setCard ( card ) { this.card = card != null ? (card instanceof Card ? card : new Card(card)) : card; }
	setUsdtAddress( v ) { this.usdtAddress = v; }
	hasUsdtAddress() { return this.usdtAddress; }
}


export class Requisite extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }

		_.assignIn(this, data);

		if ( data.account instanceof String ) this.account = _.last(data.account.split('/'))
		if ( data.user instanceof String ) this.user = _.last(data.user.split('/'))
	}
}
