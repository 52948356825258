import _ from 'lodash';
import { Model } from './model';
import { Money } from './money';

export class Recovered extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }

		_.assignIn(this, {
			mrr: data.mrr ? _.each(data.mrr, (m, i) => { data.mrr[i] = new Money(m)}) : [],
			rr: data.rr,
			currentMonth: data.currentMonth ? _.each(data.currentMonth, (m, i) => { data.currentMonth[i] = new Money(m)}) : [],
			previousMonth: data.previousMonth ? _.each(data.previousMonth, (m, i) => { data.previousMonth[i] = new Money(m)}) : [],
		})
	}
}
