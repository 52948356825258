import _ from 'lodash';
import { Model } from './model';
import { Money} from '@/models/money';

export class OnRecovery extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }

		_.assignIn(this, {
			mrr: data.mrr ? _.each(data.mrr, (m, i) => { data.mrr[i] = new Money(m)}) : [],
			numberSubscriptions: data.numberSubscriptions,
			percentSubscriptions: data.percentSubscriptions
		})
	}
}
