<template lang='pug'>
	.vkPixel(:id='`vk_pixel${id}`')
</template>
<script>
	import {STORAGE} from '@/api/storage'

	export default {
		name : 'VKCounter',
		props: {
			id : String,
			role: String
		},
		data: () => ({
			payment: STORAGE.getPayment(),
		}),
		created () {
			setTimeout( () => {
				this.insertImgPixel();

				if ( window.VK )
					return this.init();

				this.loadScript();
			}, 100)
		},

		methods : {
			insertImgPixel () {
				let a = (window.Image ? (new Image()) : document.createElement('img'));
				let s = document.getElementById('vk_pixel' + this.id);

				a.src = 'https://vk.com/rtrg?p=' + this.id;
				
				// s.parentNode.insertBefore(a, s);
				s.append(a);
			},

			loadScript () {
				var t = document.createElement("script");
				t.type="text/javascript";
				t.async=!0;
				t.src='https://vk.com/js/api/openapi.js?169';
				
				t.onload = () => {
					this.init();
				}

				document.head.appendChild(t);
			},

			init () {
				VK.Retargeting.Init(this.id);
				VK.Retargeting.Hit();
				window[this.id] = _.cloneDeep(VK);
			}
		}
	}
</script>