import _ from 'lodash';
import { Model } from './model';
import { CImage } from './image';

export class Bot extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }

		_.assignIn(this, {
			username: data.username,
			firstName: data.firstName,
			description: data.description,
			shortDescription: data.shortDescription,
			description: data.description,
			status: data.status ? data.status : data.botStatus ? data.botStatus : null,
			image: data.image ? (data.image instanceof CImage ? data.image : new CImage(data.image)) : null,
			botStatusUpdatedAt: data.botStatusUpdatedAt,
			token: data.token
		});
	}
}
