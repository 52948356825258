import { Money } from './money';
import {User} from './user'
import {ISPROD, DOMAIN_PROD} from '@/api'

export class Partner extends User {
    constructor ( data ) {
        super (data);

        this.commission = data.commission;
		this.views      = data.views,
		this.merchants  = data.merchants;
        this.projects   = data.projects;
        this.linkHash   = data.linkHash;
        this.holdDuration= data.holdDuration;
        this.cashflow   = new Money(data.cashflow);
        this.income     = new Money(data.income);

        this.link       = 'https://' + DOMAIN_PROD + '/p/' + this.linkHash;
    }

    hasFilledAccount() {
        return true;
    }
}
