import axios from 'axios'
import { API, APIURL } from '../index'

export default _.defaults({
	URL: APIURL + 'users',
	merchants(data) {
		return axios.get(`${APIURL}merchants`, { params: { ...data } });
	},

	consumers(data) {
		return axios.get(`${APIURL}consumers`, { params: { ...data } });
	},

	putImage(data, uploadCallback) {
		let formData = new FormData();
		let options = {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress: uploadCallback
		};

		if (data == null) return axios.post(`${APIURL}images`, formData, options);

		formData.append('image', data.file);

		return axios.post(`${APIURL}images`, formData, options);
	},
	subscriptions(data) {
		return axios.get(`${APIURL}subscriptions`, { params: { ...data } });
	},

	exportSubscriptions(data) {
		return axios.get(`${APIURL}subscriptions/default_export`, { params: { ...data } });
	},

	audienceStat(data) {
		let id = data.id;
		delete data.id;

		return axios.get(`${APIURL}neuro_projects/${id}/audience`, { params: { ...data } });
	},

	audienceDetails(data) {
		let id = data.id;
		delete data.id;

		return axios.get(`${APIURL}neuro_projects/${id}/audience_details`, { params: { ...data } });
	},

	audienceTable(data) {
		let id = data.id;
		delete data.id;

		return axios.get(`${APIURL}neuro_projects/${id}/audience_table`, { params: { ...data } });
	},

	signature(data) {
		return axios.post(`${APIURL}payments/signature`, data);
	},

	payment(data) {
		return axios.post(`${APIURL}payments/success`, data);
	},

	paymentDebug(data) {
		return axios.post(`${APIURL}public/payments/debug`, data);
	},

	paymentInit(data) {
		return axios.post(`${APIURL}payments/init`, data);
	},

	paymentsList(data) {
		return axios.get(`${APIURL}payments`, { params: { ...data } });
	},

	income(data) {
		return axios.get(`${APIURL}payments`, { params: { ...data } });
	},

	incomeShort(data) {
		return axios.get(`${APIURL}payments/data`, { params: { ...data } });
	},

	withdrawals(data) {
		return axios.get(`${APIURL}withdrawals`, { params: { ...data } });
	},

	offer(data) {
		return axios.get(`${APIURL}offer`, { params: { ...data } });
	},

	testUnitpay() {
		return axios.get(`${APIURL}payments/handle?method=check&params%5Baccount%5D=390d4f2d-365a-411b-9f48-9c54f0fa0bbc&params%5Bdate%5D=2020-05-25%2010:00:01&params%5Bip%5D=11.111.111.111&params%5BisPreauth%5D=0&params%5Boperator%5D=card&params%5BorderCurrency%5D=RUB&params%5BorderSum%5D=1.00&params%5BpayerCurrency%5D=RUB&params%5BpayerSum%5D=1.00&params%5BpaymentType%5D=card&params%5Bprofit%5D=0.96&params%5BprojectId%5D=203471&params%5Bpurse%5D=123456xxxxxx7890&params%5Bsignature%5D=668adbf3094ff052704be2a31789fa2664bc3062a039ae0f4a98e8e2188c66c2&params%5Bsum%5D=1&params%5Btest%5D=0&params%5BunitpayId%5D=123456`);
		//
	},

	testUnitpay2() {
		return axios.get(`${APIURL}payments/handle?method=pay&params%5B3ds%5D=1&params%5Baccount%5D=390d4f2d-365a-411b-9f48-9c54f0fa0bbc&params%5Bdate%5D=2020-05-25%2010:00:02&params%5Bip%5D=11.111.111.111&params%5BisPreauth%5D=0&params%5Boperator%5D=card&params%5BorderCurrency%5D=RUB&params%5BorderSum%5D=1.00&params%5BpayerCurrency%5D=RUB&params%5BpayerSum%5D=1.00&params%5BpaymentType%5D=card&params%5Bprofit%5D=0.96&params%5BprojectId%5D=203471&params%5Bpurse%5D=123456xxxxxx7890&params%5Bsignature%5D=668adbf3094ff052704be2a31789fa2664bc3062a039ae0f4a98e8e2188c66c2&params%5BsubscriptionId%5D=1234567&params%5Bsum%5D=1&params%5Btest%5D=0&params%5BunitpayId%5D=1234567890`);
	},
	totalMerchants() {
		return axios.get(`${APIURL}public/merchants/count`);
	},

	partnerView(data) {
		return axios.post(`${APIURL}public/partners/view`, data);
	},

	qrReferral() {
		return axios.get(`${APIURL}partners/qr_referral`, {
			responseType: 'blob'
		});
	},

	finance(data) {
		return axios.get(`${APIURL}merchants/finance`, { params: data });
	},
}, API);
