import _ from 'lodash';
import { Model } from './model';
import { CImage } from './image';
import { multyCurrency, Money } from './money';
import moment from 'moment-timezone';

export class User extends Model {
	constructor ( data ) {
		super(data);
		if ( _.isNil(data) ) { return }

		this._setFinance(data.finance);
		this._setIRole(data.roles);

		_.assignIn(this, {
			registeredAt		: data.registeredAt,
			avatar 				: data.avatar ? (data.avatar instanceof CImage ? data.avatar : new CImage(data.avatar)) : data.image ? (data.image instanceof CImage ? data.image : new CImage(data.image)) : null,
			username 			: data.username,
			tgUsername 			: data.tgUsername,
			tgId 				: data.tgId,
			status 				: data.status,
			email 				: data.email,
			roles 				: data.roles ? data.roles : [],
			finance 			: data.finance,
			avatarBg 			: CImage.BGColor(30, 60, data.tgUsername),
			subscriptions		: data.subscriptions ? data.subscriptions : null,
			balanceDiamonds		: data.balanceDiamonds,
			balanceStars		: data.balanceStars
		});
	}

	isOldUser () {
		return moment(this.registeredAt).diff(moment('2023-07-04')) < -0;
	}

	isJustRegistered () {
		let diff = moment(this.registeredAt).diff(moment());

		return diff < 0 && diff > -60000;
	}

	_setFinance (data) {
		if ( data != null ) {
			_.each(data, (d, i, o) => {
				multyCurrency(o, i);

				o[i] 			= new Money(o[i]);
				o[`${i}RUB`] 	= new Money(o[`${i}RUB`]);
			})
		}
	}

	_setIRole ( roles ) {
		if ( this.type ) return this[`is${this.type}`] = true;

		_.each(roles, r => {
			if ( r == USER_ROLES[2].id ) return;
			this[`is${filters.capitalize(r.replace('ROLE_', '').toLowerCase())}`] = true;
		});
	}
}

export const USER_ROLES = {
	USER		: 'ROLE_USER',
	ADMIN		: 'ROLE_ADMIN',
	MERCHANT	: 'ROLE_MERCHANT',
	CONSUMER	: 'ROLE_CONSUMER',
	PARTNER		: 'ROLE_PARTNER',
	MODERATOR	: 'ROLE_MODERATOR',
	SUPPORT		: 'ROLE_SUPPORT'
};
