<template lang='pug'>
	.app.h-100
		router-view
		ga(:tid='gatid')
		ya(:tid='ymtid')
		fb(id='163043472239723')
		fb(id='779151046022300')
		vk(id='VK-RTRG-1274697-4aNK6')
		//- modal-add-personal-account
</template>

<style src="@/assets/fonts/fontawesome/fontawesome.css"></style>
<style src="@/assets/fonts/golos/golos.css"></style>
<style src="@/assets/fonts/fira-code/firacode.css"></style>

<script>
import { getDirection } from "./utils";
import ColorSwitcher from "./components/Common/ColorSwitcher";
import { STORAGE, STORAGE_LAST_OPENED_ITEM } from '@/api/storage';
import { mapActions } from 'vuex';
import GA from '@/components/Widgets/Google';
import YA from '@/components/Widgets/Yandex';
import FB from '@/components/Widgets/Facebook';
import VK from '@/components/Widgets/VKCounter';
//import ModalRulesConfirm from '@/components/Modals/RulesConfirm'
// import ModalAddPersonalAccount from '@/components/Modals/AddPersonalAccount'
import { ENV } from '@/api/index';

export default {
	components: {
		'color-switcher': ColorSwitcher,
		'ga': GA,
		'ya': YA,
		'fb': FB,
		'vk': VK
		//ModalRulesConfirm
		// ModalAddPersonalAccount
	},
	data: () => ({
		ymtid: ENV.YM,
		gatid: ENV.GA,
	}),
	computed: {
		needShowModalConfirm() {
			return (this.me && this.me.id && !this.me.isLicenceAccepted && this.$route.name != 'login');
		}
	},
	async beforeCreate() {
		if (STORAGE.hasToken()) {
			try {
				if (this.$cookies.get(STORAGE_LAST_OPENED_ITEM)) {
					this.$store.commit('project/setLastOpenedProjectId', this.$cookies.get(STORAGE_LAST_OPENED_ITEM));
				}

				let u = await this.$store.dispatch('auth/me');
				if (this.$cookies.get(STORAGE_LAST_OPENED_ITEM) && !['payment', 'add_project'].includes(this.$route.name) && window.location.pathname.match(/projects\/\d*\//) == null) {
					await this.getProject({id: this.$cookies.get(STORAGE_LAST_OPENED_ITEM)});
				}
			} catch ( err ) {}
		}
	},
	beforeMount() {
		const direction = getDirection();
		if (direction.isRtl) {
			document.body.classList.add("rtl");
			document.dir = "rtl";
			document.body.classList.remove("ltr");
		} else {
			document.body.classList.add("ltr");
			document.dir = "ltr";
			document.body.classList.remove("rtl");
		}
	},
	methods: {
		...mapActions({
			getProject: 'project/id'
		})
	}
};
</script>
