<template lang='pug'>
	.vkPixel(:id='tid' v-if='tid')
</template>
<script>
	import {ISPROD} from '@/api/index'
	import { mapActions } from 'vuex'

	export default {
		name : 'GoogleAnalitics',
		props: ['tid'],
		data : () => ({
			cid : null,
		}),
		created () {
			if ( ISPROD ) {
				setTimeout(() => {
					var ta = document.createElement('script'); ta.type = 'text/javascript'; ta.async = true;
					ta.src = '//www.googletagmanager.com/gtag/js?id=' + this.tid;
					ta.onload = () => {
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());

						gtag('config', this.tid);
						this.getId();
					}
					var s = document.getElementById(this.tid);
					s.parentNode.insertBefore(ta, s);
				}, 100)
			}
		},

		methods: {
			...mapActions({
				edit : 'auth/meEdit'
			}),
			getId () {
				setTimeout( () => {
					if ( window.ga.getAll && window.ga.getAll() && window.ga.getAll()[0] ) {
						this.cid = window.ga.getAll()[0].get('clientId');
						window.gacid = this.cid;
						
						if ( this.me && this.me.id && !this.me.gaClientId )
							this.send();

						return;
					}

					this.getId();
				}, 100)
			},

			send () {
				this.edit({id: this.me.id, gaClientId: this.cid})
			}
		},

		watch: {
			me(val) {
				if (val && val.id) {
					if ( !val.gaClientId ) {
						if ( this.cid )
							this.send();
					}
				}
			},
		}
	}
</script>
