import _ from 'lodash'

export const STATUS_ARCHIVE = 'ARCHIVE';
export const STATUS_ACTIVE	= 'ACTIVE';
export const STATUS_INACTIVE= 'INACTIVE';
export const STATUS_BLOCKED	= 'BLOCKED';
export const STATUS_ERROR	= 'ERROR';
export const STATUS_REMOVED	= 'REMOVED';

export class Model {
	constructor ( data ) {
		if ( _.isNil(data) ) { return }

		_.assignIn(this, {
			id					: data.id ? data.id : (data['@id'] && _.isString(data['@id']) ? _.last(data['@id'].split('/')) : null),
			'@id' 				: data['@id'],
			type 				: data['@type'],
			createdAt 			: data.createdAt,
			updatedAt 			: data.updatedAt,
		});
	}

	setArchive ( a = true ) {
		this.status = a ? STATUS_ARCHIVE : STATUS_ACTIVE;
	}

	isArchived () { return this.status == STATUS_ARCHIVE; }
	isActive () { return this.status == STATUS_ACTIVE; }
	isInActive () { return this.status == STATUS_INACTIVE; }
	isBlocked () { return this.status == STATUS_BLOCKED; }
	isError () { return this.status == STATUS_ERROR; }
	isRemoved () { return this.status == STATUS_REMOVED; }
}

export class ModelExtend extends Model {
	constructor ( data ) {
		super( data );
		if ( _.isNil(data) ) { return }
		_.assignIn(this, data);
	}
}

