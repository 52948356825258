import { _ } from 'core-js';
import {Model} from './model'
import {Money} from './money'
import { BrightColors, RandomColor } from '@/utils'
import {APIURL} from '@/api/index'

const brightColors = BrightColors();

export class Metric extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }

		_.assignIn(this, data);
		this.firstSubIncome     = this.firstSubIncome ? new Money(this.firstSubIncome) : null;
		this.newIncomeOther     = this.newIncomeOther ? new Money(this.newIncomeOther) : null;
		this.newIncomeWorld2ru  = this.newIncomeWorld2ru ? new Money(this.newIncomeWorld2ru) : null;
		this.recurrentIncomeOther= this.recurrentIncomeOther ? new Money(this.recurrentIncomeOther) : null;
		this.recurrentIncomeWorld2ru    = this.recurrentIncomeWorld2ru ? new Money(this.recurrentIncomeWorld2ru) : null;
		this.amount    			= this.amount ? new Money(this.amount) : null;
		this.totalIncomeOther	= 0
		this.totalIncomeWorld2ru= 0

		if ( this.newIncomeOther && this.recurrentIncomeOther ) {
			this.totalIncomeOther = (Number(data.newIncomeOther.amount / 100) + Number(data.recurrentIncomeOther.amount / 100));
		}
		if ( this.newIncomeWorld2ru && this.recurrentIncomeWorld2ru ) {
			this.totalIncomeWorld2ru = (Number(data.newIncomeWorld2ru.amount/ 100) + Number(data.recurrentIncomeWorld2ru.amount / 100));
		}

		if ( this.subCategory && this.subCategory.length > 0 )
			this.subCategory = _.map(_.sortBy(this.subCategory, 'viewsCount').reverse(), c => {
				c.subCategoryId = c.id;
				c.parent 		= this.categoryType;

				if ( c.icon && !c.icon.match(/api\./) ) c.icon = APIURL + c.icon;

				return new Metric(c);
			});

		this.isExtended = false;
		this._checked   = this.subCategoryId == null;

		this._setColor();
	}

	set checked ( i ) {
		if ( i ) {
			_.each(this.subCategory, c => {
				c.checked = false;
			});
		}

		this._checked = i;
	}
	get checked () {
		if ( !this.subCategory || this.subCategory.length == 0 ) return this._checked;
		if ( this.subCategory && _.some( this.subCategory, c => c.checked ) ) return false;

		return this._checked;
	}

	isDirect ()         { return !this.isSubCategory() && this.categoryType == METRIC_TYPES[0]; }
	isFromSite ()       { return !this.isSubCategory() && this.categoryType == METRIC_TYPES[1]; }
	isSocials ()        { return !this.isSubCategory() && this.categoryType == METRIC_TYPES[2]; }
	isSearches ()       { return !this.isSubCategory() && this.categoryType == METRIC_TYPES[3]; }
	isMessengers ()     { return !this.isSubCategory() && this.categoryType == METRIC_TYPES[4]; }
	isRegisterFromBot (){ return !this.isSubCategory() && this.categoryType == METRIC_TYPES[5]; }
	isDefaultType ()    { return this.isDirect() || this.isFromSite() || this.isSocials() || this.isSearches() || this.isMessengers() || this.isRegisterFromBot() }

	isSubCategory ()    { return this.subCategoryId != null }
	hasSubCategory ()   { return this.subCategory && this.subCategory.length > 0 }
	subCategoryHasData () {
		return this.hasSubCategory() && _.every(this.subCategory, s => s.newIncomeOther != null || s.newIncomeWorld2ru != null)}

	getTitle ( list ) {
		let title = this.title ? this.title : (this.subCategoryTitle ? this.subCategoryTitle : (list[this.categoryType] ? list[this.categoryType] : null));
		return title.replace(/https?\:\/\/(www\.)?/, '');
	}

	_setColor ( index = 0 ) {
		if ( this.color ) return;

		if ( !this.isSubCategory() ) {
			switch ( this.categoryType ) {
				case METRIC_TYPES[0]:
					index = 2;
					break;
				case METRIC_TYPES[1]:
					index = 1;
					break;
				case METRIC_TYPES[2]:
					index = 3;
					break;
				case METRIC_TYPES[3]:
					index = 5;
					break;
				case METRIC_TYPES[4]:
					index = 4;
					break;
				case METRIC_TYPES[5]:
					index = 6;
					break;
				default:
					index = 0;
			}

			this.color = brightColors[index].value;
			return;
		}

		this.color = RandomColor();
	}
}

export const METRIC_TYPES = ['DIRECT', 'FROM_SITE', 'SOCIALS', 'SEARCHES', 'MESSENGERS', 'REGISTRATION_THROUGH_BOT'];
export function prepareMetricList ( items ) {
	if ( items == null ) {
		return {};
	}

	let total 	= {
		categoryType: "TOTAL",
		conversion 	: 0,
		projectsCount	: 0,
		newIncomeOther: {amount: 0, currency: "RUB"},
		newIncomeWorld2ru: {amount: 0, currency: "USD"},
		amount: {amount: 0, currency: "RUB"},
		recurrentIncomeOther: {amount: 0, currency: "RUB"},
		recurrentIncomeWorld2ru: {amount: 0, currency: "USD"},
		recurrentCount: 0,
		merchantCount: 0,
		subsCount: 0,
		viewsCount: 0,
		subCategoryTitle: null,
		colorBg		: brightColors[0].value,
		color 		: brightColors[0].value,
	};

	items.items = _.map(items.items, (i, ind) => {
		i.categoryType = ind;
		return i instanceof Metric ? i : new Metric(i);
	});

	let sortOption = 'viewsCount';

	_.each(items.items, i => {
		total.subsCount 			+= Number(i.subsCount);
		total.recurrentCount 		+= Number(i.recurrentCount);
		total.viewsCount 			+= Number(i.viewsCount);
		total.merchantCount 		+= Number(i.merchantCount);
		total.conversion 			+= Number(i.conversion);
		total.projectsCount 		+= Number(i.projectsCount);

		if ( i.amount && i.amount.amount )
			total.amount.amount 		+= Number(i.amount.amount) * 100;
		if ( i.income && i.income.amount )
			total.income.amount 		+= Number(i.income.amount) * 100;
		if ( i.newIncomeOther && i.newIncomeOther.amount )
			total.newIncomeOther.amount 		+= Number(i.newIncomeOther.amount) * 100;
		if ( i.newIncomeWorld2ru && i.newIncomeWorld2ru.amount )
			total.newIncomeWorld2ru.amount 		+= Number(i.newIncomeWorld2ru.amount) * 100;
		if ( i.recurrentIncomeOther && i.recurrentIncomeOther.amount )
			total.recurrentIncomeOther.amount+= Number(i.recurrentIncomeOther.amount) * 100;
		if ( i.recurrentIncomeWorld2ru && i.recurrentIncomeWorld2ru.amount )
			total.recurrentIncomeWorld2ru.amount+= Number(i.recurrentIncomeWorld2ru.amount) * 100;

		if ( !_.isUndefined(i.merchantCount) ) sortOption = 'merchantCount';
	})
	total.conversion = total.conversion == 0 ? 0 : total.subsCount / total.viewsCount * 100;
	total.conversion =  Math.floor(total.conversion * 100) / 100;

	items.items = _.sortBy(items.items, sortOption).reverse();
	items.items.unshift(new Metric(total));

	return items;
}
