import _ from 'lodash';
import { Model } from './model';
import {RecoveryEfficiency} from "@/models/recoveryEfficiency";

export class RecoveryEfficiencyComebacker extends Model {
	constructor(data, index) {
		super(data, index);
		if (_.isNil(data)) { return }
		_.assignIn(this, {
			title: data.title,
			plans: data.byPlans ? _.map(data.byPlans, p => { return {title: p.title, metrics: new RecoveryEfficiency(p.metrics)} }) : data.byPlans,
			years: data.byYears ? _.map(data.byYears, p => { return {title: p.title, metrics: new RecoveryEfficiency(p.metrics)} }) : data.byYears,
			metrics: new RecoveryEfficiency(data.metrics),
			color: this.lineColors()[index],
			checked: data.title === 'TOTAL',
			isExtended: false
		});
	}

	lineColors() {
		return ['AC7DD1', '87D7B0', 'FF7C7C', '7F7CFF', 'CB64D', 'E478CD', 'A4B20E', '0E6DB2', '6FA5B1', 'CA7A68'];
	}
}
