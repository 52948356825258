import _ from 'lodash'
import {Model} from './model'
import {Resource} from './resource'
import {Comebacker} from './comebacker'
import {CURRENCY, Money} from './money'
import {PAY_SYSTEM} from './account'

export class Plan extends Model {
	constructor ( data ) {
		super( data );

		if ( _.isNil(data) ) { return }

		_.assignIn(this, {
			title 			: data.title,
			titleRu 		: data.titleRu,
			price 			: new Money(data.price),
			priceFull 		: new Money(data.priceFull),
			tokens			: data.tokens,
			tokensTrial		: data.tokensTrial,
			discount		: data.tokensTrial,
			description		: data.description,
			user 			: data.user ? data.user : null,
			period 			: data.paymentInterval == null ? _.last(PERIODS) : _.find(PERIODS, p => p.id == data.paymentInterval || p.idd == data.paymentInterval),
			free 			: data.trialInterval == null ? _.first(PERIODSFREE) : _.find(PERIODSFREE, p => p.id == data.trialInterval || p.idd == data.trialInterval),
			duration 		: data.paymentInterval,
			trialInterval 	: data.trialInterval,
			isTrial 		: data.isTrial,
			channels 		: null,
			groups 		    : null,
			project 		: data.project,
			status 			: data.status,
			canBeDeleted 	: data.canBeDeleted,
			channelsCount  	: data.channelsCount,
			groupsCount    	: data.groupsCount,
			promotions		: data.promotions,
			expired			: false,
			countComebacks	: data.countComebacks,
			countSubscriptions: data.countSubscriptions,
			comebacker		: data.comebacker ? data.comebacker instanceof Comebacker ? data.comebacker : new Comebacker(data.comebacker) : null,
		});

		this.setChannels(data.channels);
		this.setGroups(data.groups);

		if ( this.trialInterval != null ) this.isTrial = true;
	}

	setCanBeDeleted (v) {
		this.canBeDeleted = v;
	}

	getResources () {
		return [...this.channels, ...this.groups];
	}

	hasActiveResources() {
		return _.filter(this.getResources(), r => r.isActive()).length > 0;
	}

	hasResource () {
		if ( (this.channelsCount && this.channelsCount > 0) || (this.groupsCount && this.groupsCount > 0) ) return true;

		return ((_.isArray(this.channels) && this.channels.length > 0) || (_.isArray(this.groups) && this.groups.length > 0));
	}

	setChannels ( items ) {
		this.channels = items != null ? _.map( items, p => _.isString(p) ? new Resource({'@id': p}) : new Resource(p)) : []
	}

	setGroups ( items ) {
		this.groups = items != null ? _.map( items, p => _.isString(p) ? new Resource({'@id': p}) : new Resource(p)) : []
	}

	setChannel ( item ) {
		let index = _.findIndex(this.channels, r => r.id == item.plan.id)

		if ( index != -1) {
			this.channels[index] = item instanceof Resource ? item : new Resource(item);
			return;
		}

		this.channels.push(item instanceof Resource ? item : new Resource(item));
	}

	setGroup ( item ) {
		let index = _.findIndex(this.groups, r => r.id == item.plan.id)

		if ( index != -1) {
			this.groups[index] = item instanceof Resource ? item : new Resource(item);
			return;
		}

		this.groups.push(item instanceof Resource ? item : new Resource(item));
	}

	updateGroup ( item ) {
		let index = _.findIndex(this.groups, r => r.id == item.id)

		if ( index != -1) {
			this.groups[index] = item instanceof Resource ? item : new Resource(item);
		}
	}

	updateChannel ( item ) {
		let index = _.findIndex(this.channels, r => r.id == item.id)

		if ( index != -1) {
			this.channels[index] = item instanceof Resource ? item : new Resource(item);
		}
	}

	getPrice () {
		return this.price.price();
	}

	getCurrency () {
		return this.price.currency;
	}

	hasBotIssue () { return (this.channels && this.channels.length > 0 && _.some(this.channels, c => c.hasBotIssue() || c.isBotRemoved())) || (this.groups && this.groups.length > 0 && _.some(this.groups, c => c.hasBotIssue() || c.isBotRemoved())) }

	isCurrency ( c ) { return this.getCurrency() == c; }
	isCurrencyRUB () { return this.isCurrency('RUB'); }
	isCurrencyEUR () { return this.isCurrency('EUR'); }
	isCurrencyUSD () { return this.isCurrency('USD'); }
	isForever () { return this.period && this.period.id == _.last(PERIODS).id}

	priceWithDiscountWithCurrency ( discount ) {
		if (this.price.currency == 'RUB') {
			return Math.round(this.price.price() / 100 * (100 - discount)) + ' ' + this.price.currencyTitle();
		} else {
			return (this.price.price() / 100 * (100 - discount)).toFixed(2).replace('.00', '') + ' ' + this.price.currencyTitle();
		}
	}

	setExpired( v ) {
		this.expired = v;
	}

	static getMinPrice (plan) {
		if ( plan.isCurrencyUSD() || plan.isCurrencyEUR() ) {
			return PLAN_PRICE_LIMITS.USD.min;
		}

		return PLAN_PRICE_LIMITS.RUB.min;
	}
	static isPeriodWeek (v) { return v == PERIODS[2].id }

	isPeriodOneTime() { return this.period.id == PERIODS[9].id }
}

export const PERIODS = [
	{id: 'P0Y0M1DT0H0M0S', idd: '+P00Y00M01DT00H00M00S', title: 'day', type: 'day', active: false},
	{id: 'P0Y0M3DT0H0M0S', idd: '+P00Y00M03DT00H00M00S', title: '3days', type: 'day', active: false},
	{id: 'P0Y0M7DT0H0M0S', idd: '+P00Y00M07DT00H00M00S', title: 'week', type: 'week', active: false},
	{id: 'P0Y0M14DT0H0M0S', idd: '+P00Y00M14DT00H00M00S', title: '2weeks', type: 'week', active: false},
	{id: 'P0Y1M0DT0H0M0S', idd: '+P00Y01M00DT00H00M00S', title: 'month', type: 'month', active: true},
	{id: 'P0Y2M0DT0H0M0S', idd: '+P00Y02M00DT00H00M00S', title: '2months', type: 'month', active: false},
	{id: 'P0Y3M0DT0H0M0S', idd: '+P00Y03M00DT00H00M00S', title: '3months', type: 'month', active: true},
	{id: 'P0Y6M0DT0H0M0S', idd: '+P00Y06M00DT00H00M00S', title: '6months', type: 'month', active: true},
	{id: 'P1Y0M0DT0H0M0S', idd: '+P01Y00M00DT00H00M00S', title: 'year', type: 'long', active: true},
	{id: 'null', title: 'forever', type: 'long', active: true}
]

export const PERIODSFREE = [
	{id: 'null', title: 'none', type: 'long'},

	{id: 'P0Y0M3DT0H0M0S', idd: '+P00Y00M03DT00H00M00S', title: '3days', type: 'day'},
	{id: 'P0Y0M7DT0H0M0S', idd: '+P00Y00M07DT00H00M00S', title: '7days', type: 'day'},
	{id: 'P0Y0M10DT0H0M0S', idd: '+P00Y00M10DT00H00M00S', title: '10days', type: 'day'},
	{id: 'P0Y0M14DT0H0M0S', idd: '+P00Y00M14DT00H00M00S', title: '14days', type: 'day'},
	{id: 'P0Y0M30DT0H0M0S', idd: '+P00Y00M30DT00H00M00S', title: '30days', type: 'day'},
];

export const PLAN_PRICE_LIMITS = {
	RUB: {
		min: 99,
		max: {
			week: 25000,
			other: 100000
		}
	},
	USD: {
		min: 5,
		max: {
			week: 100,
			other: 500
		}
	},
	EUR: {
		min: 5,
		max: {
			week: 100,
			other: 500
		}
	}
}
