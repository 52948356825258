import { defaultColor } from "./constants/config";
/*  if you want use single color theme

- ColorSwitcher component remove in 'src/App.vue' file.
- Remove multicolor section in current file
- Uncomment the two lines below

import "./assets/css/sass/themes/piaf.light.orange.scss";
import "./main";
*/

/* if you want single color remove this section:multicolor */
// var color = defaultColor;

var color = defaultColor;

if (localStorage.getItem("themeColor") && window.location.pathname != '/') {
	color = localStorage.getItem("themeColor");
}

let isLanding = () => {
	let subDomain = window.location.href.includes('partner\.') || window.location.href.includes('merchant\.') || window.location.href.includes('admin\.')
	return window.location.pathname == '/' && !subDomain;
}

let render = () => {

	if (!isLanding()) {

		import("./assets/css/vendor/dropzone.min.css");
		import('bootstrap-vue/dist/bootstrap-vue.css');
		import("./assets/css/vendor/bootstrap.min.css");
		import("./assets/css/vendor/bootstrap.rtl.only.min.css");
		import('video.js/dist/video-js.css');
		import('../node_modules/vue-multiselect/dist/vue-multiselect.min.css');
		import("./assets/css/sass/themes/piaf." + color + ".scss").then(() =>
			require("./main")
		);

		return;

	} else if (isLanding()) {
		import('bootstrap-vue/dist/bootstrap-vue.css');
		import("./assets/css/vendor/bootstrap.min.css");
		import("./assets/css/vendor/bootstrap.rtl.only.min.css");
		import("./assets/css/sass/themes/piaf.light.figma.scss").then(() =>
            require("./main")
        );

		return;
	}

	require("./main")
};

render();
/* if you want single color remove this section:multicolor */
