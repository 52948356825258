import AuthRequired from "@/utils/AuthRequired";
import {ROUTES_COMMON} from "@/routers"
/*
 * IF SOME ROUTES CHANCED
 * TELL IT TO BACKEND DEV
*/
export const routerDefaultParams = {
	linkActiveClass: "active",
	mode: "history"
}

export const routes = [
	{
		path: "/",
		beforeEnter: AuthRequired,
		name: 'index',
		redirect: "/offer",
	},
	{
		path: "/offer",
		beforeEnter: AuthRequired,
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'offer',
				// meta : {roles: []},
				component: () => import("@/views/appPartner/offer"),
			}
		]
	},
	{
		path: "/finance",
		beforeEnter: AuthRequired,
		component: () => import("@/views/app/index"),
		children: [
			{
				path: "/",
				name: 'finance',
				component: () => import("@/views/appPartner/finance"),
			},
			{
				path: "requisites/:id",
				props: true,
				name: 'requisites',
				component: () => import("@/views/app/finance/AddRequisites"),
			}
		]
	},
		ROUTES_COMMON.TERMS,
		ROUTES_COMMON.SUB_TERMS,
		ROUTES_COMMON.PRIVACY_ACCEPT,
		ROUTES_COMMON.PARTNERS_AGREEMENT,
		ROUTES_COMMON.AD_ACCEPT,
		ROUTES_COMMON.AGENCY_CONTRACT,
		ROUTES_COMMON.PRIVACY,
		ROUTES_COMMON.LOGIN,
		ROUTES_COMMON.LOGOUT,
		ROUTES_COMMON.PAYMENT_PROCESSING,
		ROUTES_COMMON.ERROR,
		ROUTES_COMMON['404'],
		ROUTES_COMMON.OTHER
];

export const routerParams = _.assignIn({routes}, routerDefaultParams);
